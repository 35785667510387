import React from "react"
import { useStaticQuery, graphql, Link, } from "gatsby"
import SEO from "../../../components/Layout/Seo"
import tw from "twin.macro"
import imageBgFotos from "../../../images/fondo_precios.svg"
import { StaticImage } from "gatsby-plugin-image"
import { PlanPaymentMethods } from "../../../components/plans/PlanPaymentMethods"
import { PlanDescription } from "../../../components/plans/PlanDescription"
import { MoreInfo } from "../../../components/Layout/MoreInfo"
import LayoutPage from "../../../components/global/Layout"
import { PlanSuggest } from "../../../components/plans/PlanSuggest"
import { IntegrationAllCard } from "../../../components/integrations/IntegrationAllCard"
import { IPageWithPlan } from "../../../components/plans/types"
import FAIcon from "../../../components/Layout/FAIcon"
import CountryLink from "../../../components/Layout/CountryLink"






const Precios = () => {




  return (
    <div
      style={{
        backgroundImage: `url(${imageBgFotos})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "calc(80vh - 0px)",
      }}
    >
      <LayoutPage>
        <div
          style={{
          }}
          css={tw`w-full  flex-col pt-150px  flex     mx-auto max-w-content px-8 justify-between `}
        >

          <h1
            className="text-gradient-secondary"
            css={tw`text-center text-3xl lg:text-4xl font-bold  lg:pt-0   lg:mt-0     `}
          >
            Integraciones
          </h1>
          <h2
            css={tw`text-2xl w-full mb-8 text-center lg:text-3xl pt-5 lg:mt-0 text-autogestiones-card`}
          >
            Elige los canales de Venta, <strong>Autogestiones</strong> lo centraliza todo.
          </h2>

          <IntegrationAllCard />
          <div
            css={tw`mt-32 mb-16 space-y-1 lg:space-y-6 mx-auto flex flex-col`}
          >

            <div
              css={tw`grid lg:grid-cols-2  mt-0 lg:my-auto  pt-0  lg:text-left text-center  `}
            >
              <div css={tw`mt-4 lg:mt-0 lg:block hidden w-full lg:w-300px 2xl:w-400px lg:ml-32    `}>
                <StaticImage
                  src="../../../images/planENTERPRISE.svg"
                  width={1150}
                  quality={100}
                  layout="fullWidth"
                  placeholder="none"
                  className="mr-0 ml-auto"
                  formats={["auto", "webp", "avif"]}
                  alt="precios"
                />{" "}
              </div>
              <div css={tw`lg:pr-24 mt-0 lg:my-auto  `}>
                <h2
                  css={tw`text-2xl lg:text-3xl mt-10 lg:pt-0 pt-100px lg:mt-0 text-autogestiones-card`}
                >
                  AUTOGESTIONES{" "}
                  <br />
                  <span className="text-gradient-secondary" css={tw`font-bold`}>
                    la manera más <br />inteligente de vender
                  </span>
                </h2>
                <p
                  css={tw`text-gray-300 text-base lg:text-base font-normal w-full pt-4`}
                >
                  Aumenta tus ventas en línea con una solución de integración de comercio electrónico integral que te ayuda a centralizar la gestión, vender en múltiples canales y mejorar la eficiencia.
                </p>


                <p css={tw`text-sm mt-4 text-gray-400`}>
                  *Sin tarjeta requerida, ni contrato.
                </p>
                <CountryLink
                  to="/servicios/autogestiones-cloud"
                  css={tw`flex space-x-4 w-full hover:bg-autogestiones-50 transition-all duration-100   rounded-lg   mt-4 bg-autogestiones-100  p-4 `}
                >
                  <div css={tw`flex space-x-4 mx-auto items-center`}>
                    <div css={tw`text-white`}>
                      <FAIcon name="stars" size="base" type="duotone" />
                    </div>
                    <span>Prueba ahora</span>
                  </div>
                </CountryLink>
              </div>

            </div>


          </div>

        </div>

      </LayoutPage>
    </div >
  )
}
export default Precios

export const Head = () =>
  <SEO
    lang="es-MX"
    title="Todas las integraciones"
    description="Con Autogestiones vende en los mejores canales de venta como Mercado Libre, WooCommerce, TiendaNube, MercadoShops y más. ."
  />

